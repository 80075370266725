<template>
<div>
  <v-container  v-for="(images,i) in items" :key="i">
    <v-carousel>
      <v-carousel-item
        v-for="(image,j) in images" :key="j"
        :src="image"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>
    </v-container>
  </div>
</template>


<script>
  export default {
    name: "ImageSlidersRealisations",
    data () {
      return {
        items: [
          [
            require('../assets/Chantier1/after1.jpg'),
            require('../assets/Chantier1/after2.jpg'),
            require('../assets/Chantier1/after3.jpg'),
          ],
        ],
      }
    },
  }
</script>