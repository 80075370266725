<template>
  <v-row align="center" justify="center">
    <v-col
       v-for="part in hostPageText" :key="part.Title"
      cols="8"
      md="8"
    >
      <v-card elevation="1" outlined :key="part.Title">
            <v-card-title>{{part.Title}} </v-card-title>
            <v-card-text> {{part.Text}} </v-card-text>
        </v-card>
    </v-col>
    <v-col cols="9"
      md="9">
    </v-col>
  </v-row>
</template>

<script>
export default{
    name: "HomeDescription",
    data() {
        return{
        hostPageText: [{
            "Title": "Description",
            "Text": "Bonjour, je suis Frédéric Babin, peintre en bâtiment depuis 15 ans, et je viens de lancer mon entreprise\
            à Quelaines Saint Gault, en Mayenne."
        }, {
            "Title": "Localisation",
            "Text": "J'opère actuellement dans la région Mayennaise entre Laval et Chateau-Gontier."
        }, {
            "Title": "Intéressé par nos services ?",
            "Text": 'Si vous êtes intéressé par nos services, vous pouvez nous contacter\
             via les coordonnées se trouvant à la page "Contact", \
            pour que nous puissions effectuer ensemble un devis de votre projet gratuitement.'
        }, ],
        }
    },
    computed: {
        totalAmount() {
            return this.costOfApples + this.costOfBananas + this.costOfCoconuts
        }
    },
    methods: {}
}
</script>

<style>
    a{
        text-decoration: none;
    }
</style>