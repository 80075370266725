<template>
  <v-card >
    <v-footer
      padless="padless"
      dark
    >
      <v-card
        flat
        tile
        width="100%"
        color="#0B2844"
        class="text-center"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4"
            :to="icon.action" 
            icon
          >
            <v-icon size="24px">
              {{ icon.name }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text ">
          {{ new Date().getFullYear() }} — <strong>Babin Peinture </strong> -<v-btn text to="/legal_mention" class="pa-1"> Mentions Légales </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default{
    name: "PageFooter",
    data: () => ({
      icons: [
        {
          name:'mdi-home',
          action: '/'
        },
        {
          name:'mdi-email',
          action: '/contact'
        },
      ]
    }),
}
</script>