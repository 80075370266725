<template>
  <v-container>
    <HomeDescription />
    <ImageSlider/>
  </v-container>
</template>

<script>
import HomeDescription from '../components/HomeDescription'
import ImageSlider from "../components/ImageSlider.vue"

  export default {
    name: 'Home_page',

    components: {
      HomeDescription,
      ImageSlider,
    },
  }
</script>
