<template>
  <v-app >
     <v-card class="overflow-hidden">
        <v-app-bar
      dark
      hide-on-scroll
      prominent
      dense
      fade-img-on-scroll
      scroll-target="#scrolling"
      width="100%"
      height="275"
      src="./assets/logo_homepage.png"
    >

            <template v-slot:extension>
              <v-tabs centered>
                <v-tab to='/'>Accueil</v-tab>
                <v-tab to="/realisation">Réalisations</v-tab>
                <v-tab to="/suppliers">Fournisseurs et Partenaires</v-tab>
                <v-tab to="/contact">Contact</v-tab>
              </v-tabs>
            </template>
        </v-app-bar>
        <v-sheet
          id="scrolling-techniques"
        >
          <v-main >
              <router-view/>
              <PageFooter/>
          </v-main>
          
        </v-sheet>
    </v-card>
    
  </v-app>
</template>

<script>

import PageFooter from "./components/PageFooter.vue"
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components: {
      PageFooter,
    }
};
</script>
